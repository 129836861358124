<template>
	<div class="register-container">
		<img src="@/assets/images/logo-top.png" alt="" class="login-top" />
		<div class="register-form">
			<div class="lg-tab">
				<div class="item" :class="msgActive?'active':''">注册会员</div>
			</div>
			<el-form ref="form" :rules="rules" :model="form">
				<el-form-item class="register-item" prop="mobile">
					<el-input v-model="form.mobile" placeholder="请输入手机号" @input="validatePhone"></el-input>
				</el-form-item>
				<el-form-item class="register-item">
					<slider-check @slideFunc="getMsgFormSon"></slider-check>
				</el-form-item>
				<el-form-item class="register-item" prop="verCode">
					<el-input v-model="form.verCode" placeholder="输入验证码" class="verCode"></el-input>
					<el-button 
						type="primary" 
						class="verCode-btn" 
						:disabled="!sendBtnDisabled"
						v-if="timeShow" 
						v-preventReClick 
						@click="getAuthCode">
							<span>发送验证码</span>
						</el-button>
						<span class="count" v-else>{{authTime}}s后可重试</span>
				</el-form-item>		
				<el-form-item class="register-item resetimg" prop="password">
					<el-input v-model="form.password" :type="registData.pwdType" placeholder="请输入密码"></el-input>
					<img :src="registData.src" @click="changeType"/>
				</el-form-item>			
				<el-form-item class="user-agreement" prop="checked">
					<el-checkbox v-model="form.checked" @change="onCheckDeal"></el-checkbox>
					通过注册，即视为你接受我们的<span @click="goDeal">《用户协议及隐私政策》</span>
				</el-form-item>
			</el-form>
			<el-button type="primary" class="register-btn" v-preventReClick @click="onRegister">立即注册</el-button>
			<div class="opear">
				<div class="opear-item"></div>
				<div class="opear-item">已有帐号？ <span class="opear-btn" @click="onLogin">马上登录</span></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { phoneRules } from "@/utils/rule.js";
	import {setToken,getWxToken} from "@/utils/auth";
	import SliderCheck from "@/views/pc/login/components/SliderCheck";
	var checkedPass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请勾选用户协议'));
      } else {
        callback();
      }
    };
	export default {
		components: {
			"slider-check": SliderCheck,
		},
		data() {
			return {
				submitCheck:false,
				sendBtnDisabled: false,
				timeShow: true,
				authTime:0,
				msgActive: true,
				form: {
					mobile: '',
					verCode: '',
					password: '',
					checked:false,
				},
				originForm: {},
				registData:{				 
					pwdType:"password",
					src:require("@/assets/icons/close-eye.png")
				},
				rules: {
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					},{ validator: phoneRules, trigger: 'blur' }],
					verCode: [{
						required: true,
						message: '输入验证码',
						trigger: 'blur'
					}],
					checked: [
						{validator:checkedPass,trigger: 'change' }
					],
					password: [{required: true,	message: '请输入新密码',trigger: 'change'},
					{ min: 6, max: 18,
					pattern: /^(?=.*[0-9a-zA-Z!@#$%^&*,\\.])[0-9a-zA-Z!@#$%^&*,\\.]{6,18}$/, 
					message: '密码只能包含字母、数字、常用特殊符号，且长度为6到18位！', trigger: 'blur' }]
				}
			};
		},
		computed: {
			redirect() {
				return this.$route.query.redirect;
			},
		},
		methods: {
			getAuthCode(){
				this.$http
				.post("/api/web/sendRegisterSms", this.form)
				.then((res) => { 
					this.$toast(res.msg)        
          this.authTime = 60;
					this.sendBtnDisabled = false;
					this.timeShow = false;
					var authTimer =  setInterval(()=>{
							this.authTime--;
							if(this.authTime<=0){
									this.timeShow = true;
									this.sendBtnDisabled = true;
									clearInterval(authTimer);
							}
					}, 1000);
        }).catch(err=>{
					this.$toast(err.msg)
				})
			},
			//滑动验证
			getMsgFormSon(data) {
				this.msgFormSon = data;
				if (this.rightPhone() && this.timeShow) {
					this.sendBtnDisabled = true;
				} else {
					this.sendBtnDisabled = false;
				}
			},
			rightPhone(){
				//利用正则对手机号匹配
				return /^1[3456789]\d{9}$/.test(this.form.mobile);
			},
			validatePhone(){
				if (this.rightPhone() && this.msgFormSon && this.timeShow) {
					this.sendBtnDisabled = true;
					// this.timeShow = true;
				} else {
					this.sendBtnDisabled = false;
					// this.timeShow = false;
				}
			},
			onLogin(){
				this.$router.push({ name: 'Login'})
			},
			onRegister(){
				this.$refs.form.validate((valid) => {       
					if (valid) {
						this.$http.post('/api/web/doRegister', this.form).then(res => {
							this.$toast(res.msg || '前往登录')
							setTimeout(() => {
								this.$router.replace({path: '/login'})
							}, 1000)
						}).catch(err => {
							this.$toast(err.msg || '注册失败')
						})
					} else {
						return false;
					}
				});
				
			},
			onCheckDeal(val){
				this.$refs.form.validate((valid) => {       
					if (val && valid) {
						this.submitCheck=true;
					} else {
						this.submitCheck=false;
						return false;
					}
				});
			},
			goDeal(){
				this.$router.push({ name: 'Agreement'})
			},
			changeType(){
				
				this.registData.pwdType = this.registData.pwdType==='password'?'text':'password';
				this.registData.src=this.registData.src==require("@/assets/icons/close-eye.png")?require("@/assets/icons/open-eye.png"):require("@/assets/icons/close-eye.png");
			}
			
		},
	};
</script>

<style lang='less' scoped>
	.register-container {
		width: 100%;
		height: 100vh;
		padding-top: 70px;
		background-image: url("~@/assets/images/login-bg.jpg");
		background-size: cover;
		.login-top {
			width: 306px;
		}
		.register-form {
			width: 400px;
			margin: 40px auto 0;
			padding: 10px 30px 30px;
			border-radius: 6px;
			background: #fff;
			
			.lg-tab {
				display: flex;
				border-bottom: 2px solid #d71a18;
				margin-bottom: 30px;
				.item {
					margin-right: 30px;
					line-height: 55px;
					font-size: 16px;
					&.active {
						color: #d71a18;
						font-weight: 700;
						// border-bottom: 2px solid #d71a18;
					}
				}
			}
			/deep/.el-form-item__content{
				display: flex;
			}
			.register-item {
				margin-bottom: 30px;
				/deep/.el-input__inner {
					background: #f4f4f4;
					border: 1px solid #dadada;
					border-radius: 4px;
					font-size: 16px;
				}
				.count{
					display: block;
					width: 120px;
					height: 40px;
					margin-left: 20px;
					color: #5f5f5f;
					background: #dddddd;
					border-radius: 4px;
				}
				.verCode {
					width: calc(100% - 140px);
				}
				.verCode-btn {
					width: 120px;
					margin-left: 20px;
					background: #d71a18;
					border: 1px solid #d71a18;
					color: #fff;
					border-radius: 4px;
					&.is-disabled {
						background: #dadada;
						border: 1px solid #dadada;
						color: #878787;
					}
				}
			}
			.resetimg{
				position: relative;
				img{
					display: block;
					position: absolute;
					right: 10px;
					top: 8px;
					width: 24px;
					height: 24px;
					cursor: pointer;
				}
			}
			.user-agreement{
				text-align: left;
				font-size: 12px;
				margin-bottom: 10px;
				/deep/.el-form-item__content{
					font-size: 12px;
					.el-checkbox{
						margin-right: 5px;
					}
				}
				span{
					cursor: pointer;
					color: #551a8b;
					&:hover{
						color: #ff0052;
					}
				}
			}
			/deep/.register-btn {
				width: 340px;
				height: 44px;
				margin-top: 10px;
				margin-bottom: 15px;
				background: #d71a18;
				border-radius: 4px;
				&.is-disabled {
					background: #dadada;
					border: 1px solid #dadada;
					color: #878787;
				}
			}
			.opear {
				display: flex;
				justify-content: space-between;
				color: #999;
				font-size: 12px;
				.opear-btn {
					color: #D71A18;
					cursor: pointer;
				}
			}
		}
	}
</style>
